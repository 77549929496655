import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
 // const baseURL = 'http://www.mcc5.com.cn/ewm/qxqk';//线上
  const baseURL =  'http://fmsb.scylsd.cn';//测试
export { baseURL }
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const http = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL,
	
	// 超时
	timeout: 60 * 1000,
});
// request拦截器
http.interceptors.request.use(
	(config) => {
		// 是否需要设置 token
		// const isToken = (config.headers || {}).isToken === false;
		// if (getToken() && !isToken) {
		// 	// config.headers["Authorization"] = "Bearer " + getToken(); 
		// 	// 让每个请求携带自定义token 请根据实际情况自行修改
		// 	config.headers["login_jiuyescm_ticket"] = getToken();
		// 	config.headers["login_cp_ticket_dev"] = getToken();
		// 	config.headers["login_cp_ticket_uat"] = getToken();
		// }
		// get请求映射params参数
		if (config.method === "get" && config.params) {
			let url = config.url + "?";
			for (const propName of Object.keys(config.params)) {
				const value = config.params[propName];
				var part = encodeURIComponent(propName) + "=";
				if (value !== null && typeof value !== "undefined") {
					if (typeof value === "object") {
						for (const key of Object.keys(value)) {
							let params = propName + "[" + key + "]";
							var subPart = encodeURIComponent(params) + "=";
							url += subPart + encodeURIComponent(value[key]) + "&";
						}
					} else {
						url += part + encodeURIComponent(value) + "&";
					}
				}
			}
			url = url.slice(0, -1);
			config.params = {};
			config.url = url;
		}
		return config;
	},
	(error) => {
		console.log(error);
		Promise.reject(error);
	}
);

// 响应拦截器
http.interceptors.response.use(
	(res) => {
		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || res.data.msg || errorCode["default"];
		if (code === 401) {
			MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
				confirmButtonText: "重新登录",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				store.dispatch("LogOut").then(() => {
					location.href = "/index";
				});
			}).catch(() => { });
		} else if (code === 500) {
			console.log(`http.js 75`, msg);
			Message({
				dangerouslyUseHTMLString: true,
				type: 'error',
				offset: 250,
				center: "center",
				customClass: 'tips-mg-lg',
				message: `
				<div class='tips-msg'>${msg}</div>
				`
			});
			// Message({
			// 	message: msg,
			// 	type: "error",
			// });
			return Promise.reject(new Error(msg));
		} else if (code !== 200) {
			Notification.error({
				title: msg,
			});
			return Promise.reject("error");
		} else {
			return res.data;
		}
	},
	(error) => {
		debugger
		let { message } = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		Message({
			dangerouslyUseHTMLString: true,
			type: 'error',
			offset: 250,
			center: "center",
			customClass: 'tips-mg-lg',
			message: `
			<div class='tips-msg'>${message}</div>
			`
		});
		// Message({
		// 	message: message,
		// 	type: "error",
		// 	duration: 5 * 1000,
		// });
		return Promise.reject(error);
	}
);

export default http;
