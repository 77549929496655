<template>
  <div class="main">
    <div class="content">
      <div class="logo">
        <!-- <img src="@/assets/index-logo.jpg" alt=""> -->

        <img style="width: 60px;" src="../../assets/logo-2.png" alt="">
      </div>
      <!-- 中小微企业欠款及民工欠薪投诉系统 -->
      <h3></h3>
      <div class="notification">
        <!-- <p class="title">填报须知</p> -->

        <!-- <div v-html="salaryArrearsNotice" id="notification_cont" class="notification_cont"></div> -->
        <div v-html="salaryArrearsNotice2" id="notification_cont" class="notification_cont"></div>
        <!-- <p style="margin-bottom: 10px;">联系电话：</p>
        <p>联系邮箱：</p> -->
      </div>
      <div class="bottom_box">
        <el-button type="primary" @click="next">进入系统<i class="el-icon-right"></i></el-button>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      salaryArrearsNotice: "",
      salaryArrearsNotice2: "<p>       xxxx有限公司开通欠薪欠费投诉渠道。如您认为xxxx及所属分子公司、项目部欠薪、欠费的，均可向我们反映问题线索。我们将根据合同及相关协议，依法依规公正处理。</p><p>投诉专线：（工作日8:30 -17:30）</p><p>   联系人：xxx</p><p>   座机号：028-xxxxxxx</p><p>   手机号：xxxxxxxxxxxx</p><p>投诉邮箱：xxxxxxxxxxxx</p>"
    }
  },
  created() {
  },
  mounted() {

    this.$http('/api/infoConfig/getInfoConfig').then(res => {
      this.salaryArrearsNotice = res.data.salaryArrearsNotice || '';
      localStorage.setItem('eventDescPrompt', JSON.stringify(res.data.eventDescPrompt));
    })
    this.initData();
  },
  methods: {

    //getUrlParam方法就是使用正则截取地址栏里的code
    getUrlParam: function (name) {

      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;

    },
    //获取用户的openid
    getOpenId(code) {
      let that = this
      let result = axios({
        method: 'get',
        url: `api/wx/getOpenId?code=${code}`,
        header: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(function (result) {

        if (result.data.access_token && result.data.openid) {
          that.getUserInfo(result.data.access_token, result.data.openid);
        };

      }).catch(error => {
        //待完善弹窗

      });
    },

    getUserInfo(token, openid) {
      let result = axios({
        method: 'get',
        url: `api/wx/getUserInfo?token=${token}&openId=${openid}`,
        header: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then(function (result) {
        localStorage.setItem('userInfo', JSON.stringify(result.data))

      }).catch(error => {
        //待完善弹窗
        // console.log('getUserInfo err', error);

      });
    },

    initData() {
      var appid = "wx0fe8d67f8bde13f0"; //个人公众号appid wx0fe8d67f8bde13f0 
      var redirect = encodeURIComponent('http://www.mcc5.com.cn/ewm/qxqk'); //重定向回来的地址
      // http://fmsb.scylsd.cn
      var wx_code = this.getUrlParam("code"); // 截取url中的code

      // if (!wx_code) {

      //   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      //   //scope=snsapi_base 静默授权

      // } else {
      //   // 获取到了code
      //   this.getOpenId(wx_code); //把code传给后台获取用户信息
      //   console.log('ru', wx_code);

      // }
      let formVal = localStorage.getItem('formVal');

      if (formVal) {
        this.ruleForm = JSON.parse(formVal)
      }

    },
    next() {
      this.$router.push('/form')
    }
  },
}
</script>
<style lang="scss" scoped>
.main {
  min-height: 100vh;
  padding: 1.5vh;
  box-sizing: border-box;
  background: -webkit-linear-gradient(left top, #3481ce, #539de7);
}

.content {
  position: relative;
  min-height: 97vh;
  padding: 0 20px;
  border-radius: 20px;

  background-color: #fff;

  .logo {
    img {
      display: block;
      width: 100%;
      margin: 0px auto;
      padding-top: 80px;
      max-width: 1000px;
    }
  }

  h3 {
    font-size: 20px;
    text-align: center;
    padding: 0px 5px 0;
    line-height: 30px;
    margin-bottom: 7vh;
    font-weight: bold;
  }

  .notification {
    padding-top: 0vh;
    padding-bottom: 20vh;

    >p {
      font-size: 14px;
    }

    // .title {
    //   text-align: center;
    //   font-weight: bold;
    //   font-size: 18px;
    //   margin-bottom: 4vh;
    // }
  }

  .notification_cont {
    padding: 0 8px;
    letter-spacing: 1px;
    line-height: 34px;
    margin-bottom: 20px;
    white-space: pre-wrap;
    // text-indent: 2em;
    // font-size: 16px;
  }

  .bottom_box {

    .el-button {
      width: 140px;

      .el-icon-right {
        margin-left: 13px;
      }
    }

    .el-button--primary {
      background-color: #1989fa;
    }

    position: absolute;
    bottom: 10vh;
    left: calc(50% - 70px);
  }
}
</style>